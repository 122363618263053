import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [
// {
//   path: '/nested',
//   component: Layout,
//   redirect: '/nested/menu1',
//   alwaysShow: true,
//   name: 'Nested',
//   meta: {
//     title: '一级菜单1',
//     icon: 'nested'
//   },
//   children: [
//     {
//       path: 'menu1',
//       component: () => import('@/views/nested/menu1/index'), // Parent router-view
//       name: 'Menu1',
//       alwaysShow: true, // 非末端菜单加入这个设置可以当只有一个菜单时，展示菜单目录
//       meta: { title: '二级菜单1-1' },
//       children: [
//         {
//           path: 'menu1-1',
//           component: () => import('@/views/nested/menu1/menu1-1'),
//           name: 'Menu1-1-1',
//           meta: { title: '三级菜单1-1-1' }
//         },
//         {
//           path: 'menu1-2',
//           component: () => import('@/views/nested/menu1/menu1-2'),
//           name: 'Menu1-2',
//           meta: { title: '三级菜单1-1-2' },
//           alwaysShow: true,
//           children: [
//             {
//               path: 'menu1-2-1',
//               component: () => import('@/views/nested/menu1/menu1-2/menu1-2-1'),
//               name: 'Menu1-1-2-1',
//               meta: { title: '四级菜单1-1-2-1' }
//             },
//             {
//               path: 'menu1-2-2',
//               component: () => import('@/views/nested/menu1/menu1-2/menu1-2-2'),
//               name: 'Menu1-1-2-2',
//               meta: { title: '四级菜单1-1-2-2' }
//             }
//           ]
//         },
//         {
//           path: 'menu1-3',
//           component: () => import('@/views/nested/menu1/menu1-3'),
//           name: 'Menu1-2-3',
//           meta: { title: '三级菜单1-2-3' }
//         }
//       ]
//     },
//     {
//       path: 'menu2',
//       name: 'Menu1-2',
//       component: () => import('@/views/nested/menu2/index'),
//       meta: { title: '二级菜单1-2' }
//     }
//   ]
// },
{
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  name: 'dashboardLayout',
  meta: {},
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/distributorHome',
  name: 'distributorHomeLayout',
  meta: {},
  component: Layout,
  redirect: '/distributorHome',
  children: [{
    path: '',
    name: 'distributorHome',
    component: function component() {
      return import('@/views/merchant/distributorHome/index');
    },
    meta: {
      title: '我的奖励',
      icon: 'my_reward'
    }
  }]
}, {
  path: '/distributorMemberPool',
  component: Layout,
  redirect: '/distributorMemberPool',
  name: 'distributorMemberPoolLayout',
  meta: {},
  children: [{
    path: '',
    name: 'distributorMemberPool',
    component: function component() {
      return import('@/views/merchant/memberPool/index');
    },
    meta: {
      title: '用户池',
      icon: 'register_member'
    }
  }]
}, {
  path: '/distributorOrderPool',
  component: Layout,
  redirect: '/distributorOrderPool',
  name: 'distributorOrderPoolLayout',
  meta: {},
  children: [{
    path: '',
    name: 'distributorOrderPool',
    component: function component() {
      return import('@/views/merchant/orderPool/index');
    },
    meta: {
      title: '奖励订单',
      icon: 'order_list'
    }
  }]
}, {
  path: '/adminDistributor',
  component: Layout,
  redirect: '/adminDistributor',
  name: 'adminDistributorLayout',
  meta: {},
  children: [{
    path: '',
    name: 'adminDistributor',
    component: function component() {
      return import('@/views/admin/adminDistributor/index');
    },
    meta: {
      title: '分销商管理',
      icon: 'distributor_admin'
    }
  }]
}, {
  path: '/adminPackageConfig',
  component: Layout,
  redirect: '/adminPackageConfig',
  name: 'adminPackageConfigLayout',
  meta: {},
  children: [{
    path: '',
    name: 'adminPackageConfig',
    component: function component() {
      return import('@/views/admin/adminPackageConfig/index');
    },
    meta: {
      title: '奖励套餐配置',
      icon: 'package_admin'
    }
  }]
}, {
  path: '/adminDistributorSettle',
  component: Layout,
  redirect: '/adminDistributorSettle',
  name: 'adminDistributorSettleLayout',
  meta: {},
  children: [{
    path: '',
    name: 'adminDistributorSettle',
    component: function component() {
      return import('@/views/admin/adminDistributorSettle/index');
    },
    meta: {
      title: '分销商结算',
      icon: 'settle_admin'
    }
  }]
}, {
  path: '/adminOrders',
  component: Layout,
  redirect: '/adminOrders',
  name: 'adminOrdersLayout',
  meta: {
    title: '管理订单',
    icon: 'order_admin'
  },
  children: [{
    path: '',
    name: 'adminOrders',
    component: function component() {
      return import('@/views/admin/adminOrders/index');
    },
    meta: {
      title: '管理订单',
      icon: 'order_admin'
    }
  }, {
    path: '/adminApplyUpdateCode',
    name: 'adminApplyUpdateCode',
    component: function component() {
      return import('@/views/admin/adminApplyUpdateCode/index');
    },
    meta: {
      title: '分销商审核',
      icon: 'order_admin'
    }
  }]
}, {
  path: '/adminRemittances',
  component: Layout,
  redirect: '/adminRemittances',
  name: 'adminRemittanceLayout',
  meta: {},
  children: [{
    path: '',
    name: 'adminRemittances',
    component: function component() {
      return import('@/views/admin/adminRemittance/index');
    },
    meta: {
      title: '打款管理',
      icon: 'order_admin'
    }
  }, {
    path: '/adminRemittances/detail',
    name: 'RemittanceDetailList',
    hidden: true,
    component: function component() {
      return import('@/views/admin/adminRemittanceDetail/index');
    },
    meta: {
      title: '打款账单详情',
      icon: 'order_admin'
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;